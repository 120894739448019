<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Estado de información"></header-title>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="showDialog()">
          <v-icon>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="status_informations"
        :loading="loading"
        :search="search"
        calculate-widths
        item-key="name"
        class="elevation-1"
        v-cloak
      >
        <template v-slot:[`item.status`]="{ item }">
          <td>
            <span
              v-if="
                item.estado == 1 || item.estado == true || item.estado == 'true'
              "
            >
              <v-chip small color="green lighten-4">activo</v-chip>
            </span>
            <span v-else>
              <v-chip small color="red lighten-4">inactivo</v-chip>
            </span>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  @click="editStatusInformation(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  color="red"
                  @click="confirmDelete(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="rdialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Estado de información</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    type="text"
                    label="Descripción *"
                    v-model="status_information.descripcion"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    autocomplete="off"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Estado"
                    :items="states"
                    v-model="status_information.estado"
                    item-text="description"
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="mode"
              @click.native="createStatusInformation()"
              >Guardar</v-btn
            >
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="!mode"
              @click.native="updateStatusInformation()"
              >Actualizar</v-btn
            >
            <v-btn color="red" text @click.native="rdialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <dialog-confirm
      :mymodal="cdialog"
      title="Alerta"
      message="¿Esta seguro(a) de eliminar el registro?"
      :btn_load="btn_loading"
      @confirm="deleteStatusInformation"
      @close="closeDialog"
    />
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import DialogConfirm from "@/components/DialogConfirm";
import Storage from "../../utils/storage";
import Service from "../../services/request";
import { mapActions } from "vuex";
export default {
  name: "status-information",
  components: {
    HeaderTitle,
    DialogConfirm,
  },
  data() {
    return {
      search: "",
      loading: false,
      btn_loading: false,
      headers: [
        { text: "#", value: "id", align: "left", sortable: false },
        { text: "Descripción ", value: "descripcion" },
        { text: "Estado ", value: "status" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      status_informations: [],
      status_information: {
        id: "",
        descripcion: "",
        estado: true,
      },
      states: [],
      rdialog: false,
      cdialog: false,
      mode: true,
    };
  },
  mounted() {
    const user = Storage.getUser();
    if (user && user.rid == 1) {
      this.states = Service.getStatus();
      this.getStatusInformation();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getStatusInformation() {
      this.loading = true;
      Service.svcStatusInformation("get")
        .then((response) => {
          this.loading = false;
          this.status_informations = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDialog() {
      this.status_information = {
        id: "",
        descripcion: "",
        estado: true,
      };
      this.mode = true;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.rdialog = true;
    },
    createStatusInformation() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        Service.svcStatusInformation("post", this.status_information)
          .then((response) => {
            this.btn_loading = false;
            this.toast({ color: "success", text: response.data });
            this.rdialog = false;
            this.getStatusInformation();
          })
          .catch(() => {
            this.btn_loading = false;
            console.error("Error al guardar");
          });
      }
    },

    editStatusInformation(data) {
      this.status_information = data;
      this.mode = false;
      this.rdialog = true;
    },

    updateStatusInformation() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        Service.svcStatusInformation("put", this.status_information)
          .then((response) => {
            this.btn_loading = false;
            this.toast({ color: "success", text: response.data });
            this.rdialog = false;
            this.getStatusInformation();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast({ color: "error", text: "No se pudo actualizar" });
          });
      }
    },

    confirmDelete(id) {
      this.status_information.id = id;
      this.cdialog = true;
    },

    deleteStatusInformation() {
      this.btn_loading = true;
      Service.svcStatusInformation("delete", this.status_information)
        .then((response) => {
          this.btn_loading = false;
          if (response.status === 204) {
            this.cdialog = false;
            this.toast({ color: "success", text: "Registro eliminado" });
            this.getStatusInformation();
          } else {
            this.toast({
              color: "error",
              text: "Registro en uso o no existe",
            });
          }
        })
        .catch(() => {
          this.btn_loading = false;
          this.toast({
            color: "error",
            text: "Registro en uso o no existe",
          });
        });
    },

    closeDialog() {
      this.cdialog = false;
    },
  },
};
</script>
